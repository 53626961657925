
import { defineComponent, ref, toRefs, watchEffect, onUnmounted } from "vue";
import { eventBus } from "@/utils";

export default defineComponent({
  name: "RiskTextModal",
  components: {},
  props: { processKey: String },
  setup(props) {
    const { processKey } = toRefs(props);
    const show = ref(false);
    const isHighRisk = ref(false);

    eventBus.on("openHighRiskTips", () => {
      isHighRisk.value = true;
    });

    watchEffect(() => {
      if (
        (processKey.value === "agile_whcscs" ||
          processKey.value === "process1526472308029102") &&
        isHighRisk.value
      ) {
        show.value = true;
      }
    });

    onUnmounted(() => {
      eventBus.off("openHighRiskTips");
    });

    return { show };
  },
});
