/* eslint-disable prettier/prettier */
import { defineComponent, watchEffect, ref, h, onMounted } from "vue";
import { Dialog } from "vant";
import fetchApi from "@/api";
import { eventBus, getUrlParams, store } from "@/utils";
import DetailPanel from "../detail/Index.vue";
import {
  ApproveButton,
  RejectButton,
  // RefuseButton,
  SignButton,
  MoreButton,
  ReassignButton,
  GroupChatButton,
} from "./buttons";
import "./index.less";
import { TaskItemProps } from "../type";
import RiskTextModal from "./RiskTextModal.vue";

let PROCESS_CODE_OFFER: string;
if (process.env.VUE_APP_ENV === "production") {
  PROCESS_CODE_OFFER = "c9972ea80e9c4387bd63153a60eaf1b1";
}
if (process.env.VUE_APP_ENV === "test" || process.env.VUE_APP_ENV === "development") {
  PROCESS_CODE_OFFER = "7fc597e0fbdf4cf680cee541a8701459";
}

interface ButtonProps {
  addButton: string;
  approveButton: boolean;
  isOuter: string;
  processInstanceId: string;
  reassignButton: boolean;
  rebackButton: boolean;
  rejectButton: boolean;
  taskId: string;
  taskName: string;
  taskType: string;
  assigneeStatus: string;
  agreeButton: boolean;
  disagreeButton: boolean;
  groupChatButton: boolean;
}
interface BaseButtonItem {
  name: string;
  component: any;
}

export default defineComponent({
  name: "approveDetail",
  setup() {
    const search = getUrlParams();
    const reqId = search.get("processInstanceId") || "";
    const signetType = ref("default");
    const maxNum = 3;
    const baseButtons: BaseButtonItem[] = [
      { name: "addButton", component: SignButton },
      { name: "reassignButton", component: ReassignButton },
      { name: "groupChatButton", component: GroupChatButton },
      // { name: "rejectButton", component: RefuseButton },
      { name: "rebackButton", component: RejectButton },
      { name: "approveButton", component: ApproveButton },
      { name: "disagreeButton", component: RejectButton },
      { name: "agreeButton", component: ApproveButton },
    ];
    const outerButtons = ref<string[]>([]);
    const innerButtons = ref<string[]>([]);
    const comps_key = ref(0);
    const isChatButton = ref(false);
    const buttonsData = ref<any>({});
    const processDefinitionKey = ref("");
    // 计算构建按钮
    watchEffect(() => {
      const { assigneeStatus, taskType } = buttonsData.value;
      if (assigneeStatus === "1") {
        signetType.value = "waitSign";
      } else if (taskType === "addsigner") {
        signetType.value = "addSign";
      }
      const buttons: string[] = [];
      baseButtons.forEach((item) => {
        if (item.name === "addButton") {
          // @ts-ignore
          if (buttonsData.value[item.name] === "1") {
            buttons.push(item.name);
          }
        } else if (item.name === "groupChatButton") {
          if (isChatButton.value) {
            buttons.push(item.name);
          }
          // @ts-ignore
        } else if (buttonsData.value[item.name]) {
          buttons.push(item.name);
        }
      });

      if (signetType.value !== "waitSign") {
        if (buttons.length > maxNum) {
          outerButtons.value = buttons.slice(buttons.length - maxNum + 1);
          innerButtons.value = buttons.slice(0, buttons.length - maxNum + 1);
        } else {
          outerButtons.value = buttons;
        }
      }
    });

    watchEffect(() => {
      if (reqId) {
        //是否可建群
        fetchApi
          .isCanCreateChat()
          .params({ processInstanceId: reqId })
          .send()
          .then((res) => {
            if (res?.success) {
              isChatButton.value = res.data;
            }
          });
        // 请求button数据
        fetchApi
          .getButtons()
          .params({ pid: reqId })
          .silent(true)
          .send()
          .then((res: { data: ButtonProps }) => {
            if (res.data) {
              buttonsData.value = res.data;
            }
          })
          .catch(() => {
            Dialog.alert({
              title: "提示",
              message: "当前任务已取消或者已完成",
            });
          });
      }
    });

    onMounted(async () => {
      let taskItem = store.get("taskItem");
      if (!taskItem) {
        await fetchApi
          .getTaskList()
          .data({ processInstanceId: reqId, pageSize: 1, pageNo: 1 })
          .send("POST")
          .then((res) => {
            const data: { result: TaskItemProps[] } = JSON.parse(res.data);
            if (data.result.length > 0) {
              store.set("taskItem", data.result[0]);
            }
          });
      }
      taskItem = store.get("taskItem");
      if (taskItem) {
        const {
          processCode,
          reqId: processInstanceId,
          processKey,
        } = store.get("taskItem") as unknown as TaskItemProps;
        if (processCode === PROCESS_CODE_OFFER) {
          eventBus.emit("decodeOffer", processInstanceId);
        }
        if (
          processKey === "agile_whcscs" ||
          processKey === "process1526472308029102"
        ) {
          processDefinitionKey.value = processKey;
        }
      }
    });

    const onReload = () => {
      comps_key.value += 1;
      outerButtons.value = [];
      innerButtons.value = [];
    };

    const renderOuterButtons = (type: string) => {
      if (type === "have") {
        return outerButtons.value.map((key) =>
          h(baseButtons.find((item) => item.name === key)?.component, {
            onReload,
          }),
        );
      } else {
        return outerButtons.value.map((key) =>
          h(baseButtons.find((item) => item.name === key)?.component, {
            onReload,
            type,
          }),
        );
      }
    };

    const renderInnerButtons = () =>
      innerButtons.value.map((key) =>
        h(baseButtons.find((item) => item.name === key)?.component, {
          block: true,
        }),
      );

    const renderContent = () => {
      if (outerButtons.value.length > 0) {
        if (innerButtons.value.length > 0) {
          return [h(MoreButton, {}, () => renderInnerButtons()), ...renderOuterButtons("have")];
        }
        return renderOuterButtons("none");
      } else {
        if (signetType.value === "waitSign") {
          return h("div", { class: "toolbar-tips", width: "100%" }, "等待加签中，加签完成后可进行审批");
        }
        return h("div");
      }
    };

    return () =>
      h("div", { class: "approveDetail-wrapper" }, [
        h(DetailPanel, { signetType: signetType.value, key: comps_key.value, title: process.env.NODE_ENV === "production" ? "审批详情" : "审批详情（H5）" }),
        h("div", { class: "toolbar-wrapper" }, [
          h(
            "div",
            {
              class: {
                "toolbar-content": true,
                "toolbar-content-space": outerButtons.value.length + innerButtons.value.length > 2,
              },
            },
            renderContent(),
          ),
        ]),
        h(RiskTextModal, { processKey: processDefinitionKey.value }),
      ]);
  },
});
